<template>
  <div>
    <JobDetail />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import JobDetail from "@/components/JobListDetail/jobListDetailMobile.vue";

export default {
  components: {
    JobDetail
  },
  created() {
        const {
      fullPath,
      params: { uuid }
    } = this.$route;
    this.urlFullPath = fullPath;
    this.jobBoardDetailAction({job_uuid: uuid, path: this.urlFullPath});
  },
  methods:{
    ...mapActions(['jobBoardDetailAction'])
  }
};
</script>
