<template>
  <div class="job-board">
    <CModal
      id="applyjobModal"
      :title="modalTitle"
      color="primary"
      centered:false
      :show.sync="successModal"
      >{{ this.modalMessage }}
      <template #footer>
        <span>
          <CButton
            class="ml-2"
            variant="outline"
            color="primary"
            @click="applyModalCallBack(false)"
            >Cancel</CButton
          >
          <CButton
            class="ml-2"
            color="primary"
            @click="applyModalCallBack(true)"
            >Confirm</CButton
          >
        </span>
      </template>
    </CModal>
    <div class="text-right">
      <div>
        <CButton @click="goBack()" color="primary" shape="pill">Back</CButton>
      </div>
    </div>
    <div class="job-board-detail-component container-fluid">
      <div>
        <CRow class="d-flex justify-content-center">
          <img
            :src="fileSource.logo"
            class="rounded-circle ratio-1"
            style="width: 60%; height: 60%"
            @error="
              $event.target.src = '/img/hospital.png';
              $event.target.style = 'width: 100px; height: 100px';
            "
          />
        </CRow>
      </div>
      <div class="title">
        <p>{{ organisation }}</p>
        <h6 class="text-primary mt-2">
          <em class="fas fa fa-map-marker-alt"></em>&nbsp;
          <span style="color: #00000099">{{ location }}</span>
        </h6>
      </div>
      <div class="title mt-3">
        <h5 class="text-center">{{ title }}</h5>
      </div>
      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Candidate Type</div>
          <div class="value text-left">{{ candidateType }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label">Candidate Level</div>
          <div class="value">{{ candidateLevel }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Job Reference</div>
          <div class="value text-left">{{ jobReference }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label">Contract</div>
          <div class="value">{{ contract }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Date Posted</div>
          <div class="value text-left">{{ createdDate }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label">Expiry Date</div>
          <div class="value">{{ expiryDate }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-action" v-if="!isAdmins">
        <div class="detail-action-item">
          <span class="f-1_5 action" @click="favouriteJob(favouriteID)">
            <em
              :class="`${
                favouriteID !== null
                  ? 'text-primary fas fa-heart'
                  : 'far fa-heart'
              }`"
            ></em>
          </span>
        </div>
        <div class="detail-action-item text-left">
          <social-sharing
            class="share"
            v-if="true"
            :url="sharing.url"
            :title="sharing.title"
            :description="sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
            :twitter-user="sharing.twitterUser"
            inline-template
          >
            <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <CIcon name="cil-share-alt" class="color-primary" />
                </div>
              </template>
              <CDropdownItem>
                <network network="email">
                  <em class="fa fa-envelope"></em> Email
                </network>
              </CDropdownItem>
              <div>
                <CDropdownItem>
                  <network network="facebook">
                    <em class="fab fa-facebook-f"></em> Facebook
                  </network>
                </CDropdownItem>
              </div>
            </CDropdown>
          </social-sharing>
        </div>
      </div>
      <div v-if="!isAdmins">
        <div class="action-group">
          <button
            v-if="!appliedID"
            type="button"
            name="JobApply"
            class="btn btn-primary btn-block mb-3"
            @click="onApplyJob()"
          >
            Apply Now
          </button>
          <button
            type="button"
            class="btn btn-secondary text-primary btn-block mb-3"
            v-else
            @click="onWithdrawJob()"
            :disabled="is_rejected"
          >
            Withdraw
          </button>
          <CBadge v-if="is_rejected && appliedID" color="danger" class="p-2"
            >Application Unsuccessful</CBadge
          >
        </div>
      </div>

      <div v-else class="action-group">
        <CDropdown
          placement="bottom-start"
          v-if="isToAllowEdit && isDraftOrPending"
        >
          <template #toggler>
            <div class="row ml-0 mr-0 justify-content-between">
              <button type="button" class="btn btn-primary" style="width: 80%">
                Edit
              </button>
              <span @click="showNotification()" class="cursor-pointer">
                <CIcon name="cil-info" class="hinticon-multi-select" />
              </span>
            </div>
          </template>
          <a @click="onEdit()" class="dropdown-item" style="cursor: pointer">
            Edit Job
          </a>
          <a
            v-if="jobDescription"
            @click="editAdvertDescription()"
            class="dropdown-item"
            style="cursor: pointer"
          >
            Edit Advert
          </a>
        </CDropdown>
        <social-sharing
          :url="url"
          :title="title"
          :description="jobReference"
          inline-template
        >
          <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
            <template #toggler-content>
              <div class="d-flex align-items-center text">
                <CIcon name="cil-share-alt" class="color-primary" />
              </div>
            </template>
            <CDropdownItem>
              <network network="email">
                <em class="fa fa-envelope"></em> Email
              </network>
            </CDropdownItem>
            <div>
              <CDropdownItem>
                <network network="facebook">
                  <em class="fab fa-facebook-square"></em> Facebook
                </network>
              </CDropdownItem>
            </div>
          </CDropdown>
        </social-sharing>
      </div>
      <div v-if="isToAllowEdit" class="action-group">
        <button
          v-if="isActiveJob || isInActiveJob"
          type="button"
          class="btn btn-primary btn-block mb-3"
          @click="publishOrUnpublish('unpublish')"
        >
          Make Draft
        </button>
        <button
          v-else-if="!isPendingJob"
          type="button"
          class="btn btn-primary btn-block mb-3"
          @click="publishOrUnpublish('publish')"
        >
          Publish Job
        </button>
        <button
          v-if="
            Roles.includes(currentUserRole) &&
            ![Role.systemAdmin].includes(currentUserRole) &&
            (isActiveJob || isDraftOrPending) &&
            isToAllowEdit
          "
          type="button"
          class="btn btn-primary btn-block mb-3"
          @click="onInActiveJob()"
        >
          Make Inactive
        </button>
      </div>
      <div class="text-center">
        <img
          :src="fileSource.image"
          style="width: 80%; max-height: 250px"
          @error="$event.target.src = '/img/job-image.jpg'"
          alt="banner image"
        />
      </div>
      <div class="job-description-content" v-html="jobDescription"></div>
    </div>
    <CModal
      id="DraftJob"
      title="Draft Job"
      centered:true
      color="primary"
      :show.sync="draftModal"
    >
      <p>{{ draftModalMessage }}</p>
      <template #footer-wrapper>
        <div class="d-flex justify-content-end px-4">
          <button @click="onCancelDraft" class="btn btn-secondary mb-3 mr-3">
            Cancel
          </button>
          <button @click="onDraftSave" class="btn btn-primary mb-3">
            Save as Draft
          </button>
        </div>
      </template>
    </CModal>

    <CModal
      id="PublishJob"
      title="Publish Job"
      centered:false
      color="primary"
      :show.sync="publishModal"
    >
      <p>Do you want to Publish the selected job?</p>
      <template #footer-wrapper>
        <div class="d-flex justify-content-end px-4">
          <button @click="onCancelPublish" class="btn btn-secondary mb-3 mr-3">
            Cancel
          </button>
          <button @click="onPublish" class="btn btn-primary mb-3">
            Publish Job
          </button>
        </div>
      </template>
    </CModal>
    <CModal
      id="inActivejobModal"
      title="Inactive Job"
      color="primary"
      centered:false
      @update:show="onUpdateJobStatus"
      :show.sync="updateModal"
    >
      <p>Are you sure you want to deactivate this job?</p>
    </CModal>
    <EditAdvertAloneModal
      v-if="editAdvertAlone.isShowPopup"
      :isShowPopup="editAdvertAlone.isShowPopup"
      :jobData="editAdvertAlone.jobData"
      :modalCallBack="editAdvertAloneCallBack"
    />
    <CModal
      id="notification-modal"
      title="NOTIFICATION"
      color="primary"
      @update:show="notificationModal.show = false"
      :show.sync="notificationModal.show"
    >
      <p v-html="notificationModal.content"></p>
      <template #footer>
        <span>
          <CButton
            class="ml-2"
            variant="outline"
            color="primary"
            @click="notificationModal.show = false"
            >Cancel</CButton
          >
        </span>
      </template>
    </CModal>
  </div>
</template>

<script>
import jobListDetail from "@/components/JobListDetail/jobListDetail.vue";

export default {
  extends: jobListDetail,
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>
